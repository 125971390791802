
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Raleway:wght@300;400;600;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Raleway:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Crimson+Text:wght@400;600;700&family=Fauna+One&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Raleway:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unna:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.carousel-container{
  height: 
  300px;
 }

 .clip-s-shape {
  
    clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 10%);

  
}

.linked-element {
  max-width: 100%;
 /* Adjust the value to match your navbar's height */
}





.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000000;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #9c7301;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loader2 {

  animation: fadeIn 2.5s;
}

.loader3{
  animation: fadeOut 2.5s;
}

.loader4{
  animation: shrink 2.5s;
}



.glowing-div {
  border: 3px solid transparent;
 
  animation: glowing 2s infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes shrink {
  0% { transform: scaleX(0); }
  70% { transform: scaleX(1.5); }
  100% { transform: scaleX(0); }
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-10px); }
  70% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px)}
}

@keyframes fadeOut {
  0% { opacity: 0; transform: translateY(10px); }
  70% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(10px); }
}


@keyframes glowing {
  0%,
  100% {
    border-color: rgba(188, 113, 0, 0.6);
    box-shadow: 0 0 10px rgba(188, 113, 0, 0.6);
  }
  50% {
    border-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  }
}


/* RotatingDiv.css */
.flip-container {
  perspective: 1000px;
  width: 200px;
  height: 200px;
}

.flipper {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flipped .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front {
  background-color: #3498db;
}

.back {
  background-color: #e74c3c;
  transform: rotateY(180deg);
}


 .border-grow-before::before,
 .border-grow-after::after {
   content: '';
   position: absolute;
   width: 0;
   height: 0;
   border: 2px solid transparent;
   transition: width 0.5s, height 0.5s;
 }
 
 .border-grow-before::before {
   top: -2px;
   left: -2px;
 }
 
 .border-grow-after::after {
   bottom: -2px;
   right: -2px;
 }
 
 .border-grow-before:hover::before,
 .border-grow-after:hover::after {
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   border-color: blue;
 }
 

.grid-container {
    display: grid;
    grid-template-areas: 
      "a a b"
      "a a c";
    gap: 1rem;
  }
  
  .card {
    padding: 1rem;
    border: 1px solid #ccc;
  }
  
  .card-a {
    grid-area: a;
  }
  
  .card-b {
    grid-area: b;
  }
  
  .card-c {
    grid-area: c;
  }



  /* .swiper-button-next {
    color: #e74c3c;
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center;
  } */
  
  /* .swiper-button-next::after {
    display: none;
  } */


  

  ::-webkit-scrollbar-track {
    background-color:#ffffff;;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color:#000000; 

  }

  ::-webkit-scrollbar-thumb:hover {
    background-color:  #888;
  }